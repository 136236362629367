/* nav-search */
.icon-search {
	background-image: url("~@/assets/icons/icon-search.svg");
}
.icon-search-grey {
	background-image: url("~@/assets/icons/icon-search-grey.svg");
}
.icon-clean {
	background-image: url("~@/assets/icons/icon-clean.svg");
}
/* nav */
.icon-addADeal {
	background-image: url("~@/assets/icons/icon-addADeal.svg");
}
.icon-dropAlert {
	background-image: url("~@/assets/icons/icon-dropAlert.svg");
}
.icon-Cn {
	background-image: url("~@/assets/icons/icon-Cn.svg");
}
.icon-En {
	background-image: url("~@/assets/icons/icon-En.svg");
}
.icon-signin {
	background-image: url("~@/assets/icons/icon-signin.svg");
}

.icon-list {
	background-image: url("~@/assets/icons/icon-list.svg");
}

/* side bar */
.icon-topy {
	background-image: url("~@/assets/icons/icon-topy.svg");
}
.icon-like {
	background-image: url("~@/assets/icons/icon-like.svg");
}
.icon-rank {
	background-image: url("~@/assets/icons/icon-rank.svg");
}
.icon-wishlist {
	background-image: url("~@/assets/icons/icon-wishlist.svg");
}
.icon-toTop {
	background-image: url("~@/assets/icons/icon-toTop.svg");
}

.icon-close {
	background-image: url("~@/assets/icons/icon-close.svg");
}

/* user center */
.icon-edit {
	background-image: url("~@/assets/icons/icon-edit.svg");
}

/* chat */
.icon-send {
	background-image: url("~@/assets/icons/icon-send.svg");
}

/* feature icons */
.icon-feature-bigCut {
	background-image: url("~@/assets/icons/icon-feature-bigCut.svg");
}
.icon-feature-mostPopular {
	background-image: url("~@/assets/icons/icon-feature-mostPopular.svg");
}
.icon-feature-limited {
	background-image: url("~@/assets/icons/icon-feature-limited.svg");
}
.icon-feature-gifts {
	background-image: url("~@/assets/icons/icon-feature-gifts.svg");
}
.icon-feature-mostComments {
	background-image: url("~@/assets/icons/icon-feature-mostComments.svg");
}
.icon-feature-hot {
	background-image: url("~@/assets/icons/icon-feature-hot.svg");
}
.icon-feature-rating {
	background-image: url("~@/assets/icons/icon-feature-rating.svg");
}

/* goods panelbtn */
.icon-noLikeGoods {
	background-image: url("~@/assets/icons/icon-noLikeGoods.svg");
	opacity: 0.2;
}
.icon-remove {
	background-image: url("~@/assets/icons/icon-remove.svg");
	opacity: 0.2;
}

.icon-likeGoods {
	background-image: url("~@/assets/icons/icon-likeGoods.svg");
}
.icon-shareGoods {
	background-image: url("~@/assets/icons/icon-shareGoods.svg");
	opacity: 0.2;
}

/* menu */
.icon-enter {
	background-image: url("~@/assets/icons/icon-enter.svg");
	opacity: 0.2;
}
.btn-enter:hover .icon-enter {
	background-image: url("~@/assets/icons/icon-enter-white.svg");
	opacity: 1;
}
.btn-like:hover .icon-noLikeGoods {
	background-image: url("~@/assets/icons/icon-noLikeGoods-white.svg");
	opacity: 1;
}
.btn-like:hover .icon-likeGoods {
	background-image: url("~@/assets/icons/icon-likeGoods-white.svg");
	opacity: 1;
}

/* 移动端 */
.icon-mobile-menu {
	background-image: url("~@/assets/icons/icon-mobile-menu.svg");
}
.icon-home {
	background-image: url("~@/assets/icons/icon-home.svg");
}

/* 用户中心移动端 */
.icon-user-info {
	background-image: url("~@/assets/icons/icon-user-info.svg");
}

.icon-back {
	background-image: url("~@/assets/icons/icon-back.svg");
}

/* 详情-相关推荐 */
.icon-prev {
	background-image: url("~@/assets/icons/icon-prev.svg");
}
.icon-next {
	background-image: url("~@/assets/icons/icon-next.svg");
}

.icon-more-stores {
	background-image: url("~@/assets/icons/icon-more-stores.svg");
}
