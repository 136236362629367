/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

/* You can override the default Infima variables here. */
:root {
	--ifm-color-primary: #8542c3;
	--ifm-color-primary-light: #33925d;
	--ifm-color-primary-lighter: #359962;
	--ifm-color-primary-lightest: #3cad6e;
	--ifm-code-font-size: 95%;
	--docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.1);
	--ifm-navbar-height: auto;
	--ifm-heading-font-family: Poppins;
	--ifm-heading-margin-bottom: 1.5rem;
	--ifm-navbar-shadow: none;
	font-size: 16px;

	--rengage-navbar-vertical-padding: 32px;
	// vertical padding of the module container
	--rengage-module-gap: 160px;
	// horizontal padding of the module container
	--rengage-module-horizontal-gap: 155px;
	--rengage-module-vertical-gap: 160px;
	// vertical padding of the navbar
	--rengage-nav-height: calc(43px + var(--rengage-navbar-vertical-padding) * 2);
	--rengage-banner-top-gap: 72px;

	@media screen and (width > 1680px) {
		--rengage-max-width: 1920px;
		--rengage-navbar-horizontal-padding: 155px;
	}

	@media screen and (width > 996px) and (width <= 1680px) {
		--rengage-max-width: 1680px;
		--rengage-navbar-horizontal-padding: 80px;
	}

	@media only screen and (width<=996px) {
		--rengage-banner-top-gap: 24px;
		--rengage-navbar-vertical-padding: 16px;
		--rengage-navbar-horizontal-padding: 16px;
		--rengage-nav-height: calc(
			32px + var(--rengage-navbar-vertical-padding) * 2
		);
		--rengage-module-horizontal-gap: 16px;
		--rengage-module-vertical-gap: 40px;
		--rengage-module-gap: 88px;
	}
}

.col {
	padding: 0;
}

.row {
	margin: 0;
	gap: 60px;
}

.row_gap {
	@media screen and (max-width: 996px) {
		column-gap: 24px;
		row-gap: 32px;
	}
}
.footer_logo_box {
	img {
		width: 116px;
		height: 30px;
	}
}
.navbar {
	padding: 0;
	width: 100%;
	img {
		width: 116px;
		height: 30px;
	}
}

.navbar__item {
	@media screen and (width<996px) {
		display: none !important;
	}
}

.navbar__inner {
	margin: 0 !important;
}

.dropdown--hoverable {
	padding-left: 20px !important;
	padding-right: 16px !important;
}

.navbar--rengage {
	padding: var(--rengage-navbar-vertical-padding)
		var(--rengage-navbar-horizontal-padding);

	@media screen and (width<=996px) {
		justify-content: space-between;
		padding: var(--rengage-navbar-vertical-padding);
	}
}

.rengage-center-navbar {
	max-width: var(--rengage-max-width);
	width: 100%;
	align-self: center;
}

.rengage-active-navbar {
	border-radius: 100px;
	background: #ebdbff;
}

.rengage-container {
	max-width: var(--rengage-max-width);
	width: 100%;
}

.center {
	display: flex;
	justify-content: center;
}

:where(.button--secondary:not(:hover)) {
	--ifm-button-border-color: #000;
}

.sora {
	font-family: Sora, sans-serif;
	font-style: normal;
	font-weight: 500;
}

.poppins {
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 400;
}

.textCenter {
	text-align: center;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-16 {
	margin-top: 16px;
}

.px-16 {
	padding-left: 16px;
	padding-right: 16px;
}

.rengage__icon_dropdoen_menu {
	display: flex;
	align-items: center;
	gap: 16px;
}

h1,
h2,
h3,
h4,
p {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none !important;
}

p {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

h1 {
	font-family: Sora, sans-serif;
	font-style: normal;
	font-weight: 500;
	color: var(--Greyscale-Black, #131a2b);
	line-height: 140%;
}

h2 {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Sora, sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
}

// Highlighted h2
.rengage__h2_highlight {
	color: var(--Primary-Purple, #8542c3);
}

h3 {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Sora, sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

h4 {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Sora, sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.rengage__subtitle {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 400;
}

.rengage__smallCard_title {
	color: var(--Greyscale-1000, #1a2339);
	font-family: Sora, sans-serif;
	font-style: normal;
}

@media screen and (min-width: 996px) {
	h1 {
		font-size: 3.5rem;
	}

	h2 {
		font-size: 3rem;
	}

	h3 {
		font-size: 2rem;
	}

	h4 {
		font-size: 1.625rem;
	}

	.rengage__subtitle {
		font-size: 1.25rem;
		line-height: 160%;
	}

	.rengage__smallCard_title {
		font-size: 1.625rem;
		font-weight: 500;
		line-height: 130%;
	}
}

@media screen and (max-width: 996px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.25rem;
	}

	.rengage__subtitle {
		font-size: 1rem;
		line-height: 150%;
	}

	.rengage__smallCard_title {
		font-size: 1rem;
		font-weight: 600;
		line-height: 160%;
	}
}

.rengage__primary_btn {
	border-radius: 12px;
	background: var(--Greyscale-Black, #131a2b);
	color: #fff;
	transition: all 0.2s ease-in-out;
	border: 1px solid #131a2b;
	font-size: 20px;
	cursor: pointer;
	font-family: Poppins, sans-serif;
	padding: 16px 32px;
	font-weight: bold;

	@media screen and (max-width: 996px) {
		font-size: 16px;
	}

	&:hover {
		background-color: transparent;
		color: #131a2b;
		border: 1px solid #131a2b;
	}
}

.rengage__blank_btn {
	border-radius: 12px;
	background-color: transparent;
	color: #131a2b;
	transition: all 0.2s ease-in-out;
	border: 1px solid #131a2b;
	font-size: 20px;
	cursor: pointer;
	font-family: Poppins, sans-serif;
	padding: 16px 32px;
	font-weight: bold;

	@media screen and (width<=996px) {
		font-size: 16px;
	}

	&:hover {
		background: var(--Greyscale-Black, #131a2b);
		color: #fff;
		border: 1px solid #131a2b;
	}
}

.markdown-page {
	p {
		margin-bottom: 20px;
	}
}
