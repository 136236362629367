.search-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	cursor: text;
	position: relative;
	border-radius: 24px;
	flex-grow: 1;
	background: rgba(255, 255, 255, 0.2);
	padding-right: 50px;

	:global {
		.ant-select-selector {
			width: 100%;
		}
		.ant-input-search-button {
			background: none;
			&:hover {
				background: none !important;
			}
		}
		.ant-input-group-addon {
			background: none;
			&:hover {
				background: none;
			}
		}
		.ant-input-search .ant-input-group .ant-input-affix-wrapper {
			border-start-start-radius: 60px;
			border-end-start-radius: 60px;
			border: none;
			background: none;
		}
		.ant-input-outlined:focus-within {
			box-shadow: none !important;
		}
	}
}

@media only screen and (max-width: 600px) {
	.search-container {
		height: 40px;
	}
}
