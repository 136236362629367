.ads {
	display: block;
	width: 200px;
	height: 300px;
	position: fixed;
	right: 20px;
	bottom: 20px;
}

@media only screen and (max-width: 600px) {
	.ads {
		display: none;
	}
}
